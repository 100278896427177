p {
    margin: 0;
}

.day-container {
    height: 130px;
    padding: 0 15px;
    border-bottom: 1px solid #ccc;
    overflow-y: auto;
}

.day-container::-webkit-scrollbar {
    display: none;
}

.day-header {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    padding-bottom: 10px;
    margin-top: 12px;
    margin-bottom: 10px;
    justify-content: space-between;
    border-bottom: 1px dashed #0070d2;
}

.day-title {
    color: #000;
    font-size: 16px;
    text-align: left;
    text-decoration: underline;
}

.day-body {
    margin-bottom: 15px;
}

.day-button {
    position: relative;
    padding: 5px 15px;
    color: #0070d2;
    background-color: transparent;
    border: 1px solid #0070d2;
    border-radius: 4px;
    text-align: center;
    text-decoration: none;
}

.day-button:hover {
    padding: 5px 15px;
    color: white;
    background-color: #0070d2;
    border: 1px solid #0070d2;

    border-radius: 4px;
    text-align: center;
}
