.dayBookWrap {
    display: flex;
    flex-direction: row;
}

.dbNav {
    border:none;
    background:none;
    width:50px;
    cursor: pointer;
}

.dbNav:hover, .dbNav:active {
    background-color: #0070d233;
}

.toPrint {
    background-color:#fff;
    color:#000;
    width:700px;
    padding:10px;
    box-sizing:border-box;
    border:1px solid #ccc;
    margin:10px auto;
    font-family: sans-serif;
    font-size:16px;
    overflow-x:hidden;
}

.toPrint h3 {
    font-size:16px;
    text-decoration: underline;
}

.switch {
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
}
.switch span {
    margin-right:10px;
    color: #0070d2;
    font-size:14px;
    text-align: center;
}

@media screen and (max-width:1000px) {
    .toPrint {
        width:300px;
    }

    .switch {
        flex-direction: column;
    }
}