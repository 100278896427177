@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400);
.candidates {
    border:1px solid #D8DDE6;
    background-color:#fff;
    width: 800px;
    border-radius: 5px;
    padding:20px;
    list-style: none;
    box-sizing: border-box;
    margin:10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.candidates li a{
    margin:10px 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    border:1px solid #eee;
    -webkit-align-items: center;
            align-items: center;
    padding:10px;
    margin: 10px;
    text-decoration: none;
    color:#0070d2;
    width:200px;
}

.candidateName {
    margin-top:10px;
}
.code {
    color:#666;
}

.events {
    list-style: none;
    padding:0;
}

.events li {
    border-radius: 5px;
    background-color:#fff;
    /* padding:10px; */
    width:800px;
    border:1px solid #D8DDE6;
    margin-bottom:15px;
}
.events li h3 {
    margin:5px 0;
    font-size: 22px;
}

.events li .meta {
    /* time and date */
    padding:10px;
}

.events li .meta p, .events li .meta .formP{
    /* time and date */
    font-style: normal;
    color:#666;
    font-size:14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 400;
    margin:4px 0;
}

.meta a {
    color: #0070d2;
}

.note {
    display: block;
    border-left:2px solid #eee;
    padding-left:10px;
    margin:5px 20px;
}

.transmissions {
    border-top:1px solid #D8DDE6;
    margin:10px 0;
    padding:5px 15px;
}

.transmissions li, .feeds li {
    border:none;
    background: none;
    list-style: none;
    padding:0;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.transmissions li div, .feeds li div {
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

.transmissions li .button, .feeds li .button {
    margin-right:50px;
    height:1.3em;
    min-width:55px;
}

/* .transmissions li:after {
    content:'';
    padding-top:10px;
    border-bottom:1px dotted #ccc;
    width:770px;
} */

.transmissions ul, .feeds ul {
    padding:0 20px;
}   

.transmissions ul li, .feeds ul li {
    list-style: disc;
    font-weight: bold;
    text-transform: uppercase;
    font-size:14px;
}

.loglink {
    margin-left:20px;
    color:#666;
    font-size:14px;
    line-height: 25px;
    text-transform: uppercase;
}

.loglink:hover {
    color:#0070d2;
}

.loglink .linkicon {
    height:0.75em;
}

.txtime {
    color:#666;
    font-size: 14px;
}

.txicon {
    margin-right:10px;
}

.multicandidates {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content:flex-start;
            justify-content:flex-start;
    padding-left:0 !important;
}

.multicandidates img {
    margin:5px;
}

@media screen and (max-width:1000px) {
    .txsep {
        width:320px;
    }
}
.dayBookWrap {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.dbNav {
    border:none;
    background:none;
    width:50px;
    cursor: pointer;
}

.dbNav:hover, .dbNav:active {
    background-color: #0070d233;
}

.toPrint {
    background-color:#fff;
    color:#000;
    width:700px;
    padding:10px;
    box-sizing:border-box;
    border:1px solid #ccc;
    margin:10px auto;
    font-family: sans-serif;
    font-size:16px;
    overflow-x:hidden;
}

.toPrint h3 {
    font-size:16px;
    text-decoration: underline;
}

.switch {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:row;
            flex-direction:row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
.switch span {
    margin-right:10px;
    color: #0070d2;
    font-size:14px;
    text-align: center;
}

@media screen and (max-width:1000px) {
    .toPrint {
        width:300px;
    }

    .switch {
        -webkit-flex-direction: column;
                flex-direction: column;
    }
}
p {
    margin: 0;
}

.day-container {
    height: 130px;
    padding: 0 15px;
    border-bottom: 1px solid #ccc;
    overflow-y: auto;
}

.day-container::-webkit-scrollbar {
    display: none;
}

.day-header {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    padding-bottom: 10px;
    margin-top: 12px;
    margin-bottom: 10px;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    border-bottom: 1px dashed #0070d2;
}

.day-title {
    color: #000;
    font-size: 16px;
    text-align: left;
    text-decoration: underline;
}

.day-body {
    margin-bottom: 15px;
}

.day-button {
    position: relative;
    padding: 5px 15px;
    color: #0070d2;
    background-color: transparent;
    border: 1px solid #0070d2;
    border-radius: 4px;
    text-align: center;
    text-decoration: none;
}

.day-button:hover {
    padding: 5px 15px;
    color: white;
    background-color: #0070d2;
    border: 1px solid #0070d2;

    border-radius: 4px;
    text-align: center;
}

.multi-daybook-container {
    padding: 25px;
}

.multi-title {
    text-align: center;
}

.multi-buttons {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
}

.multi-button {
    color: white;
    background-color: #0070d2;
    border: 1px solid white;
    border-radius: 5px;
    text-decoration: none;
    padding: 5px;
}

.multi-button:hover {
    color: #0070d2;
    background-color: transparent;
    border: 1px solid #0070d2;
    border-radius: 5px;
    text-decoration: none;
    padding: 5px;
}

.weekContainer {
    width: 740px;
    background-color: white;
    padding: 0px 0px;
    border: solid 1px #ccc;
    border-radius: 8px;
}

.week-header {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin: 15px;
}

.day-container:last-child {
    border-bottom: none;
}

.feeds {
    list-style: none;
    padding:0;
}

.feed {
    border-radius: 5px;
    background-color:#fff !important;
    padding:10px !important;
    width:800px;
    border:1px solid #D8DDE6 !important;
    margin-bottom:15px;
}

.feed .story, .feed .story a {
    font-size:14px;
    font-weight:bold;
    text-transform: uppercase;
    color:#666;
    max-width:30%;
}

.over {
    opacity:0.5;
}
.scheduleWrap {
  position: fixed;
  top:73px;
  left:0;
  right:0;
  width:100%;
  bottom:0;
  overflow:scroll;
}

.innerSchedule {
  position: relative;
  width:3125px;
}

.topRow {
  position: -webkit-sticky;
  position: sticky;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  min-width: 100%;
  top:0;
  z-index: 20;
}

.topRowCell {
  min-width:125px;
  background-color:#eee;
  text-align: left;
  border-bottom:1px solid #aaa;
  box-shadow: rgba(0,0,0,0.3) 2px 2px 5px;
  padding:5px;
  box-sizing: border-box;
  font-weight:bold;
}

.innerAndRow {
  position: relative;
  overflow-x:scroll;
}

.staticTopLeft {
  position: -webkit-sticky;
  position: sticky;
  top:0;
  left:0;
  width:125px;
}

.now {
  background-color:#c00;
  color:#fff;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  font-size:12px;
  position: absolute;
  z-index: 25;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding:0 10px;
  height:30px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.nowBar {
  width:3px;
  background-color:#c00;
  position: absolute;
  top:0;
  bottom:0;
  z-index: 15;
}

.axis {
  min-width:125px;
  box-sizing: border-box;
  height:75px;
  background-color:#eee;
  border-right:1px solid #aaa;
  border-bottom:1px solid #ccc;
  padding:5px 0;
  font-weight:bold;
  position: -webkit-sticky;
  position: sticky;
  left:0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  box-shadow: rgba(0,0,0,0.3) 2px 2px 5px;
}

.row {
  display: -webkit-flex;
  display: flex;
  height:75px;
  box-sizing:border-box;
  border-bottom:1px solid #ccc;
}

.cell {
  border-right:1px solid #ccc;
  min-width:125px;
  box-sizing:border-box;
}

.event {
  position: absolute;
  background-color:#fff;
  border:1px solid #999;
  border-radius: 5px;
  border-left:5px solid #00c;
  height:75px;
  padding:10px;
  box-sizing: border-box;
  box-shadow: rgba(0,0,0,0.3) 2px 2px 5px;
  z-index:10;
  overflow: hidden;
}
body {
  margin:0;
  background-color:#F4F6F9;
  font-family: 'Source Sans Pro', sans-serif;
  color: #16325c;
}

.App {
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.loginHeader {
  width:300px;
  margin:10px auto;
}

.login {
  box-sizing: border-box;
  width:300px;
  background-color: #fff;
  margin:0 auto;
  padding:10px;
  border-radius: 5px;
  border:1px solid #D8DDE6 !important;
}

.topNav {
  background-color: #fff;
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  margin:0;
  padding:5px 10px;
  box-sizing: border-box;
  border-bottom:1px solid #D8DDE6;
}

.topNav ul {
  list-style: none;
  display: -webkit-flex;
  display: flex;
}

.topNav li {
  margin:0 10px;
}

.userbtn {
  position: relative;
}

.userbtn ul {
  background-color: #fff;
  box-shadow: 2px 2px 2px #00000066;
  position: absolute;
  padding:0;
}

.userbtn ul button {
  border:none;

}

h1 {
  margin:0;
  font-size:25px;
}

h1 a {
  text-decoration: none;
  color: #16325c;
}

h1 a:hover {
  text-decoration: underline;
  color:#0070d2;
}

h2 {
  max-width:800px;
  margin:10px 0;
  font-size: 30px;
  color:#0070d2;
  font-weight: 300;
  -webkit-flex-grow:1;
          flex-grow:1;
}

.header {
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width:650px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: 0 auto;
}

button, .button {
  font-family: 'Source Sans Pro', sans-serif;
  padding:5px;
  border:none;
  border-radius:5px;
  font-size:14px;
  outline:none !important;
  cursor: pointer;
  border:1px solid #0070d2;
  color:#0070d2;
  text-decoration: none;
  background-color: #fff;
}

button.asLink {
  border:none;
  padding:0;
  text-decoration: underline;
  background: none;
}

.inBox {
  float:right;
  margin:20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.inBox button {
  margin-bottom:10px;
}

button.danger {
  background-color:#c00;
  color:#fff;
  border:1px solid #a00;
}

button.cta, .button.cta {
  background-color:#0070d2;
  color:#fff;
}

.cta:disabled {
  background-color:#0070d266;
}

.empty {
  color:#999;
  margin:5px 0 15px 0;
}

.prefix {
  color:#999;
  font-size:0.8em;
}

.candidatePhoto, .smallCandidate {
  height:100px;
  border-radius: 50px;
  display:block;
  margin:0 auto;
}

.smallCandidate {
  height:30px;
}

.form {
  display: block;
  background-color:#fff;
  padding:10px;
  border-radius: 5px;
  border:1px solid #D8DDE6 !important;
}

.formRow {
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: flex-start;
          align-items: flex-start;  
}

.formRow.reqd:after {
  content:'*';
  color:#c00;
}

.formRow label {
  padding:5px;
  text-transform: uppercase;
  font-size:12px;
  font-weight: bold;
  letter-spacing: 1px;
}

.formRow.error label {
  color:#c00;
}

.formRow.error input, .formRow.error textarea {
  border:1px solid #c00;
  background-color:#fee;
}

.form input, .meta input, .form select, .meta select, .form input[type=date], .form input[type=time], .meta input[type=date], .meta input[type=time], textarea {
  font-size:14px;
  font-family: 'Source Sans Pro', sans-serif;
  border:1px solid #aaa;
}

.formRow input, .formRow select, .formRow textarea, .formFiller, .formRow div {
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.formRow div {
  display: -webkit-flex;
  display: flex;
  position: relative; 
}

.autocomplete-dropdown-container {
  position: absolute !important;
  top:1em;
  margin-top:5px;
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width:100%;
  border:1px solid #aaa;
  box-shadow:2px 2px 2px #00000066;
}

.autocomplete-dropdown-container:empty {
  border:none;
  box-shadow: none;
}

.autocomplete-dropdown-container .suggestion-item, .autocomplete-dropdown-container .suggestion-item--active {
  padding:5px;
}

.formRow textarea {
  min-height:100px;
}

.icon {
  color:#999;
  margin:0 10px;
}

.mobileMenu {
  padding:10px 15px;
}

.mobileMenuOpen {
  position: absolute;
  background-color:#fff;
  top: 35px;
  right: 0;
  padding: 0;
  box-shadow: 0 2px 3px #00000066;
}

.mobileMenuOpen li {
  padding:5px 0;
}

.lowprof {
  border:none;
  color:#333;
}

@media screen and (max-width:1000px) {
  .topNav ul {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  
  .header {
    width:280px;
  }
  .candidates {
    width: 350px;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .events li {
    width:350px;
  }

  .events li .meta p {
    margin-bottom: 10px;
    padding-left:2em;
    text-indent:-2.75em;
  }
}
