.scheduleWrap {
  position: fixed;
  top:73px;
  left:0;
  right:0;
  width:100%;
  bottom:0;
  overflow:scroll;
}

.innerSchedule {
  position: relative;
  width:3125px;
}

.topRow {
  position: sticky;
  display: flex;
  flex-direction: row;
  min-width: 100%;
  top:0;
  z-index: 20;
}

.topRowCell {
  min-width:125px;
  background-color:#eee;
  text-align: left;
  border-bottom:1px solid #aaa;
  box-shadow: rgba(0,0,0,0.3) 2px 2px 5px;
  padding:5px;
  box-sizing: border-box;
  font-weight:bold;
}

.innerAndRow {
  position: relative;
  overflow-x:scroll;
}

.staticTopLeft {
  position: sticky;
  top:0;
  left:0;
  width:125px;
}

.now {
  background-color:#c00;
  color:#fff;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  font-size:12px;
  position: absolute;
  z-index: 25;
  display: flex;
  justify-content: center;
  align-items: center;
  padding:0 10px;
  height:30px;
  width: fit-content;
}

.nowBar {
  width:3px;
  background-color:#c00;
  position: absolute;
  top:0;
  bottom:0;
  z-index: 15;
}

.axis {
  min-width:125px;
  box-sizing: border-box;
  height:75px;
  background-color:#eee;
  border-right:1px solid #aaa;
  border-bottom:1px solid #ccc;
  padding:5px 0;
  font-weight:bold;
  position: sticky;
  left:0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: rgba(0,0,0,0.3) 2px 2px 5px;
}

.row {
  display: flex;
  height:75px;
  box-sizing:border-box;
  border-bottom:1px solid #ccc;
}

.cell {
  border-right:1px solid #ccc;
  min-width:125px;
  box-sizing:border-box;
}

.event {
  position: absolute;
  background-color:#fff;
  border:1px solid #999;
  border-radius: 5px;
  border-left:5px solid #00c;
  height:75px;
  padding:10px;
  box-sizing: border-box;
  box-shadow: rgba(0,0,0,0.3) 2px 2px 5px;
  z-index:10;
  overflow: hidden;
}