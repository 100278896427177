@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400');

body {
  margin:0;
  background-color:#F4F6F9;
  font-family: 'Source Sans Pro', sans-serif;
  color: #16325c;
}

.App {
  display:flex;
  flex-direction: column;
  align-items: center;
}

.loginHeader {
  width:300px;
  margin:10px auto;
}

.login {
  box-sizing: border-box;
  width:300px;
  background-color: #fff;
  margin:0 auto;
  padding:10px;
  border-radius: 5px;
  border:1px solid #D8DDE6 !important;
}

.topNav {
  background-color: #fff;
  display:flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin:0;
  padding:5px 10px;
  box-sizing: border-box;
  border-bottom:1px solid #D8DDE6;
}

.topNav ul {
  list-style: none;
  display: flex;
}

.topNav li {
  margin:0 10px;
}

.userbtn {
  position: relative;
}

.userbtn ul {
  background-color: #fff;
  box-shadow: 2px 2px 2px #00000066;
  position: absolute;
  padding:0;
}

.userbtn ul button {
  border:none;

}

h1 {
  margin:0;
  font-size:25px;
}

h1 a {
  text-decoration: none;
  color: #16325c;
}

h1 a:hover {
  text-decoration: underline;
  color:#0070d2;
}

h2 {
  max-width:800px;
  margin:10px 0;
  font-size: 30px;
  color:#0070d2;
  font-weight: 300;
  flex-grow:1;
}

.header {
  display:flex;
  flex-direction: row;
  width:650px;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

button, .button {
  font-family: 'Source Sans Pro', sans-serif;
  padding:5px;
  border:none;
  border-radius:5px;
  font-size:14px;
  outline:none !important;
  cursor: pointer;
  border:1px solid #0070d2;
  color:#0070d2;
  text-decoration: none;
  background-color: #fff;
}

button.asLink {
  border:none;
  padding:0;
  text-decoration: underline;
  background: none;
}

.inBox {
  float:right;
  margin:20px;
  display: flex;
  flex-direction: column;
}

.inBox button {
  margin-bottom:10px;
}

button.danger {
  background-color:#c00;
  color:#fff;
  border:1px solid #a00;
}

button.cta, .button.cta {
  background-color:#0070d2;
  color:#fff;
}

.cta:disabled {
  background-color:#0070d266;
}

.empty {
  color:#999;
  margin:5px 0 15px 0;
}

.prefix {
  color:#999;
  font-size:0.8em;
}

.candidatePhoto, .smallCandidate {
  height:100px;
  border-radius: 50px;
  display:block;
  margin:0 auto;
}

.smallCandidate {
  height:30px;
}

.form {
  display: block;
  background-color:#fff;
  padding:10px;
  border-radius: 5px;
  border:1px solid #D8DDE6 !important;
}

.formRow {
  display:flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;  
}

.formRow.reqd:after {
  content:'*';
  color:#c00;
}

.formRow label {
  padding:5px;
  text-transform: uppercase;
  font-size:12px;
  font-weight: bold;
  letter-spacing: 1px;
}

.formRow.error label {
  color:#c00;
}

.formRow.error input, .formRow.error textarea {
  border:1px solid #c00;
  background-color:#fee;
}

.form input, .meta input, .form select, .meta select, .form input[type=date], .form input[type=time], .meta input[type=date], .meta input[type=time], textarea {
  font-size:14px;
  font-family: 'Source Sans Pro', sans-serif;
  border:1px solid #aaa;
}

.formRow input, .formRow select, .formRow textarea, .formFiller, .formRow div {
  flex-grow: 1;
}

.formRow div {
  display: flex;
  position: relative; 
}

.autocomplete-dropdown-container {
  position: absolute !important;
  top:1em;
  margin-top:5px;
  display:flex;
  flex-direction: column;
  width:100%;
  border:1px solid #aaa;
  box-shadow:2px 2px 2px #00000066;
}

.autocomplete-dropdown-container:empty {
  border:none;
  box-shadow: none;
}

.autocomplete-dropdown-container .suggestion-item, .autocomplete-dropdown-container .suggestion-item--active {
  padding:5px;
}

.formRow textarea {
  min-height:100px;
}

.icon {
  color:#999;
  margin:0 10px;
}

.mobileMenu {
  padding:10px 15px;
}

.mobileMenuOpen {
  position: absolute;
  background-color:#fff;
  top: 35px;
  right: 0;
  padding: 0;
  box-shadow: 0 2px 3px #00000066;
}

.mobileMenuOpen li {
  padding:5px 0;
}

.lowprof {
  border:none;
  color:#333;
}

@media screen and (max-width:1000px) {
  .topNav ul {
    flex-direction: column;
  }
  
  .header {
    width:280px;
  }
  .candidates {
    width: 350px;
    justify-content: center;
  }

  .events li {
    width:350px;
  }

  .events li .meta p {
    margin-bottom: 10px;
    padding-left:2em;
    text-indent:-2.75em;
  }
}