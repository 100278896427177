.feeds {
    list-style: none;
    padding:0;
}

.feed {
    border-radius: 5px;
    background-color:#fff !important;
    padding:10px !important;
    width:800px;
    border:1px solid #D8DDE6 !important;
    margin-bottom:15px;
}

.feed .story, .feed .story a {
    font-size:14px;
    font-weight:bold;
    text-transform: uppercase;
    color:#666;
    max-width:30%;
}

.over {
    opacity:0.5;
}