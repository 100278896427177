.candidates {
    border:1px solid #D8DDE6;
    background-color:#fff;
    width: 800px;
    border-radius: 5px;
    padding:20px;
    list-style: none;
    box-sizing: border-box;
    margin:10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.candidates li a{
    margin:10px 0;
    display: flex;
    flex-direction: column;
    border:1px solid #eee;
    align-items: center;
    padding:10px;
    margin: 10px;
    text-decoration: none;
    color:#0070d2;
    width:200px;
}

.candidateName {
    margin-top:10px;
}