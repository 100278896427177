.multi-daybook-container {
    padding: 25px;
}

.multi-title {
    text-align: center;
}

.multi-buttons {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row-reverse;
}

.multi-button {
    color: white;
    background-color: #0070d2;
    border: 1px solid white;
    border-radius: 5px;
    text-decoration: none;
    padding: 5px;
}

.multi-button:hover {
    color: #0070d2;
    background-color: transparent;
    border: 1px solid #0070d2;
    border-radius: 5px;
    text-decoration: none;
    padding: 5px;
}

.weekContainer {
    width: 740px;
    background-color: white;
    padding: 0px 0px;
    border: solid 1px #ccc;
    border-radius: 8px;
}

.week-header {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 15px;
}

.day-container:last-child {
    border-bottom: none;
}
