.code {
    color:#666;
}

.events {
    list-style: none;
    padding:0;
}

.events li {
    border-radius: 5px;
    background-color:#fff;
    /* padding:10px; */
    width:800px;
    border:1px solid #D8DDE6;
    margin-bottom:15px;
}
.events li h3 {
    margin:5px 0;
    font-size: 22px;
}

.events li .meta {
    /* time and date */
    padding:10px;
}

.events li .meta p, .events li .meta .formP{
    /* time and date */
    font-style: normal;
    color:#666;
    font-size:14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 400;
    margin:4px 0;
}

.meta a {
    color: #0070d2;
}

.note {
    display: block;
    border-left:2px solid #eee;
    padding-left:10px;
    margin:5px 20px;
}

.transmissions {
    border-top:1px solid #D8DDE6;
    margin:10px 0;
    padding:5px 15px;
}

.transmissions li, .feeds li {
    border:none;
    background: none;
    list-style: none;
    padding:0;
    display:flex;
    flex-direction: row;
}

.transmissions li div, .feeds li div {
    flex-grow: 1;
}

.transmissions li .button, .feeds li .button {
    margin-right:50px;
    height:1.3em;
    min-width:55px;
}

/* .transmissions li:after {
    content:'';
    padding-top:10px;
    border-bottom:1px dotted #ccc;
    width:770px;
} */

.transmissions ul, .feeds ul {
    padding:0 20px;
}   

.transmissions ul li, .feeds ul li {
    list-style: disc;
    font-weight: bold;
    text-transform: uppercase;
    font-size:14px;
}

.loglink {
    margin-left:20px;
    color:#666;
    font-size:14px;
    line-height: 25px;
    text-transform: uppercase;
}

.loglink:hover {
    color:#0070d2;
}

.loglink .linkicon {
    height:0.75em;
}

.txtime {
    color:#666;
    font-size: 14px;
}

.txicon {
    margin-right:10px;
}

.multicandidates {
    display:flex;
    flex-direction: row;
    justify-content:flex-start;
    padding-left:0 !important;
}

.multicandidates img {
    margin:5px;
}

@media screen and (max-width:1000px) {
    .txsep {
        width:320px;
    }
}